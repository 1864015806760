import MuiLink from '@mui/material/Link';
import { Link as GatsbyLink, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const Link = React.forwardRef((props, ref) => {
  const isFragmentLink = props.href?.startsWith('#');
  const useGatsbyLink = props.href?.startsWith('/') && !isFragmentLink;

  // if this is an anchor link, smooth scroll to the target element
  const handleLinkClick = useCallback(
    (e) => {
      // if there's already an onClick handler, execute it first
      if (typeof props.onClick === 'function') props.onClick();

      // only act on fragment identifiers
      if (!isFragmentLink) return;

      // prevent default link behavior
      e.preventDefault();

      // get the element we should scroll to
      const hash = props.href.replace('#', '');
      const scrollTargetElement = document.getElementById(hash);

      // make sure the element exists on the page
      if (
        scrollTargetElement &&
        typeof scrollTargetElement.scrollIntoView === 'function'
      ) {
        scrollTargetElement.scrollIntoView({
          behavior: 'smooth',
        });

        // set hash in URL
        window.history.pushState(null, null, `#${hash}`);
      } else {
        // else, redirect the user manually
        navigate(props.href);
      }
    },
    [isFragmentLink, props],
  );

  return useGatsbyLink ? (
    <MuiLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      activeClassName="current-page"
      component={GatsbyLink}
      onClick={handleLinkClick}
      to={props.href}
    />
  ) : (
    <MuiLink
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      component="a"
      href={props.href}
      onClick={handleLinkClick}
    />
  );
});

Link.propTypes = {
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  onClick: null,
};

export default Link;
