import { Slide, useScrollTrigger } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

// hides the component (e.g. AppBar) when you scroll down, show it when you scroll up
const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();
  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
    >
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HideOnScroll;
