import IconSelectArrow from '@mui/icons-material/ArrowDropDownRounded';
import IconWarning from '@mui/icons-material/WarningAmberRounded';
import { grey, red } from '@mui/material/colors';
import {
  alpha,
  createTheme,
  lighten,
  responsiveFontSizes,
} from '@mui/material/styles';
import { kebabCase, mapKeys } from 'lodash';
import React from 'react';

// @todo let's use the Extinct theme ;)
const fontFamily = [
  '"Cabin"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

// const unicodeRange = 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
const fontFace = [
  // variable fonts
  /*
  {
    fontFamily: '"Cabin var"',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400 700',
    fontStretch: '75%',
    fontVariationSettings: "'wdth' 75",
    unicodeRange,
    src: [
      {
        file: '/fonts/Cabin-Variable.woff2',
        format: 'woff2',
      },
    ],
  },
  {
    fontFamily: '"Cabin var"',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: '400 700',
    fontStretch: '75%',
    fontVariationSettings: "'wdth' 75",
    unicodeRange,
    src: [
      {
        file: '/fonts/Cabin-VariableItalic.woff2',
        format: 'woff2',
      },
    ],
  },
  */
  // fonts for older browsers
  {
    fontFamily: 'Cabin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: [
      {
        file: '/fonts/Cabin-Regular.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-Regular.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: [
      {
        file: '/fonts/Cabin-RegularItalic.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-RegularItalic.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: [
      {
        file: '/fonts/Cabin-Medium.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-Medium.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 500,
    src: [
      {
        file: '/fonts/Cabin-MediumItalic.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-MediumItalic.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: [
      {
        file: '/fonts/Cabin-SemiBold.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-SemiBold.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 600,
    src: [
      {
        file: '/fonts/Cabin-SemiBoldItalic.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-SemiBoldItalic.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: [
      {
        file: '/fonts/Cabin-Bold.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-Bold.woff',
        format: 'woff',
      },
    ],
  },
  {
    fontFamily: 'Cabin',
    fontStyle: 'italic',
    fontDisplay: 'swap',
    fontWeight: 700,
    src: [
      {
        file: '/fonts/Cabin-BoldItalic.woff2',
        format: 'woff2',
      },
      {
        file: '/fonts/Cabin-BoldItalic.woff',
        format: 'woff',
      },
    ],
  },
];
// convert to string snd then kebab-case for CSS
const fontFaceKebabCase = fontFace
  .map((props) => {
    const src = props.src
      .map(({ file, format }) => `url("${file}") format("${format}")`)
      .join(',');

    return {
      ...props,
      src,
    };
  })
  .map((f) => mapKeys(f, (v, k) => kebabCase(k)));

// convert each font-face declaration to CSS
let fontFaceCss = '';
fontFaceKebabCase.forEach((style) => {
  fontFaceCss += `@font-face{${Object.entries(style)
    .map(([k, v]) => `${k}:${v}`)
    .join(';')}}`;
});

// extracted values that should synchronize with theme config to maintain consistency
const PRIMARY_COLOR = '#ff5722';
const BORDER_RADIUS = 8;
const ALGOLIA_OVERLAY_Z_INDEX = 1101; // should be zIndex.appBar + 1

// MUI theme options: https://mui.com/customization/theming/
const muiThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      light: lighten('rgb(51, 171, 159)', 0.25),
      main: '#009688',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#121212',
      // dark mode uses a backgroundImage for paper:
      paperImage:
        'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
    },
    text: {
      // the text color when using the glow effect
      glow: lighten('rgb(51, 171, 159)', 0.5),
    },
    grey,
  },
  typography: {
    fontFamily,
    // fontFamily: 'inherit', // since this is a variable font, we must use 'inherit' to inherit the @supports rule override
    h1: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.375rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    caption: {
      lineHeight: 1,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  components: {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          warning: <IconWarning fontSize="inherit" />,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          isolation: 'isolate', // fixes an issue in Safari where `overflow: hidden`` doesn't work when used with with border-radius
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '& .product-icon': {
            padding: '0.25em',
            marginLeft: '0.25em',
            marginRight: '-0.375em',
          },
          '& .fa-icon': {
            padding: '0.25em',
            marginLeft: '0.25em',
            marginRight: '-0.375em',
          },
        },
        sizeSmall: {
          '& .product-icon': {
            marginLeft: 0,
          },
          '& .fa-icon': {
            marginLeft: 0,
          },
        },
      },
    },
    MuiCssBaseline: {
      // global CSS
      styleOverrides: `
        ${fontFaceCss}
        /* additional resets */
        figure {
          margin: 0;
        }
        /* Algolia search */
        .aa-DetachedOverlay {
          --aa-overlay-color-rgb: 0, 0, 0 !important;
          --aa-overlay-color-alpha: 0.6 !important;
          --aa-detached-modal-max-width: 760px !important;
          --aa-detached-modal-max-height: 620px !important;
          z-index: ${ALGOLIA_OVERLAY_Z_INDEX} !important;
          backdrop-filter: blur(5px) !important;
        }
        .aa-DetachedContainer {
          --height: 44px !important;
        }
        .aa-DetachedFormContainer {
          --aa-primary-color-rgb: 255, 87, 34 !important;
          border-bottom: 0 !important;
        }
        .aa-Form {
          border-radius: ${BORDER_RADIUS}px !important;
        }
        .aa-Input::placeholder {
          color: rgba(255, 255, 255, 0.7) !important;
        }
        .aa-Item {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }
        .aa-Item .aa-ItemTitle {
          font-size: 16px !important;
          line-height: 1.25 !important;
        }
        /* improve CWV by disabling gatsby-image fade */
        .gatsby-image-wrapper [data-main-image] {
          opacity: 1 !important;
          transition: opacity 0s !important;
        }
      `,
      /*
      styleOverrides: `
        ${fontFaceCss}
        html {
          font-family: ${fontFamily};
        }
        @supports (font-variation-settings: normal) {
          html {
            font-family: "Cabin var", ${fontFamily};
            font-variation-settings: 'wdth' 75;
          },
        },
      `,
      */
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          // fixes an issue where the Divider pseudo-elements cover sibling elements
          '::before': {
            pointerEvents: 'none',
          },
          '::after': {
            pointerEvents: 'none',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.fa-icon': {
            padding: 2,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: alpha(PRIMARY_COLOR, 0.1),
            '&:hover': {
              backgroundColor: alpha(PRIMARY_COLOR, 0.15),
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          '& .fa-icon': {
            width: '0.875em',
            height: '0.875em',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: IconSelectArrow,
      },
    },
    MuiSpeedDialAction: {
      styleOverrides: {
        staticTooltipLabel: {
          paddingLeft: 8,
          paddingRight: 8,
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        enterTouchDelay: 0, // show Tooltip immediately on tap on mobile
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: 'div',
          subtitle2: 'div',
        },
      },
    },
  },
};

// create a theme instance
// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme(muiThemeOptions);

// make font sizes responsive
theme = responsiveFontSizes(theme);

export { fontFace, theme };
