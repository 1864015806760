import PropTypes from 'prop-types';
import React from 'react';

import AlgoliaSearchScript from './AlgoliaSearchScript';
import ExitIntentNewsletterSignupDialog from './ExitIntentNewsletterSignupDialog';
import Footer from './Footer';
import MenuBar from './MenuBar';

const PageLayout = ({ children }) => {
  return (
    <>
      <MenuBar />
      {children}
      <Footer />
      <ExitIntentNewsletterSignupDialog />
      <AlgoliaSearchScript />
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  children: null,
};

export default PageLayout;
