import {
  Box,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { getProductIcon } from '../helpers/icons';
import { urls } from '../helpers/urls';
import { CURRENT_YEAR } from '../helpers/utils';
import Logo from '../images/logos/logo.svg';
import AffiliateDisclosure from './AffiliateDisclosure';
import Link from './Link';
import NewsletterSignupForm from './NewsletterSignupForm';

const moreSectionMenuItems = [
  {
    text: 'What to buy each sale holiday',
    href: urls.holidays,
  },
  {
    text: `What's on sale today`,
    href: urls.today,
  },
  {
    text: `What to buy every month in ${CURRENT_YEAR}`,
    href: urls.monthly,
  },
];

const infoSectionMenuItems = [
  {
    text: 'Home',
    href: urls.home,
  },
  {
    text: 'Feedback & contact',
    href: urls.contact,
  },
  {
    text: 'Privacy policy',
    href: urls.privacy,
  },
  {
    text: 'Do not sell my personal information',
    href: urls.myDataRights,
  },
];

const FooterMenu = React.memo(({ items, title, children, id }) => (
  <Grid
    id={id}
    sx={{ width: '100%' }}
    lg={3}
    md={4}
    sm={6}
    xs={12}
    item
  >
    <List
      component="nav"
      dense
      disablePadding
    >
      <ListSubheader
        component="div"
        disableSticky
      >
        {title}
      </ListSubheader>
      {items.map((item) => (
        <ListItemButton
          key={`${item.text}-${item.href}`}
          component={Link}
          title={item.title}
          href={item.href}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                minWidth: 44,
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </List>
    {children}
  </Grid>
));

FooterMenu.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.node,
    }),
  ).isRequired,
  children: PropTypes.node,
};

FooterMenu.defaultProps = {
  children: null,
  id: null,
};

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      popularProducts: allSitePage(
        filter: {
          component: {
            regex: "/^.*/templates/product.jsx.*$/" # only include pages using the product template
          }
          context: { popular: { eq: true } }
        }
      ) {
        edges {
          node {
            path
            context {
              articleTitle
              identifier
              name
            }
          }
        }
      }
    }
  `);
  const popularProducts = useMemo(() => {
    return sortBy(
      data.popularProducts.edges.map(({ node }) => ({
        text: node.context.name,
        href: node.path,
        icon: getProductIcon(node.context.identifier),
        title: node.context.articleTitle,
      })),
      ['text'],
    );
  }, [data.popularProducts.edges]);

  return (
    <Box
      component="footer"
      sx={{ mt: 4 }}
    >
      <Container maxWidth="lg">
        <NewsletterSignupForm
          id="newsletter-footer"
          sx={{ maxWidth: 'sm', mx: 'auto' }}
          variant="elevation"
        />
      </Container>
      <Paper sx={{ py: 4, mt: 2 }}>
        <Grid
          justifyContent={{
            xs: 'flex-start',
            md: 'center',
          }}
          spacing={2}
          sx={{ width: '100%', maxWidth: 'xl', mx: 'auto' }}
          container
        >
          <FooterMenu
            id="footer-popular"
            title="Popular"
            items={popularProducts}
          />
          <FooterMenu
            id="footer-more"
            title="More"
            items={moreSectionMenuItems}
          />
          <FooterMenu
            id="footer-info"
            title={
              <Link
                color="textSecondary"
                href={urls.home}
                title="Best Time To Buy"
              >
                <Logo width={90} />
              </Link>
            }
            items={infoSectionMenuItems}
          >
            <AffiliateDisclosure
              sx={{ m: 2 }}
              alwaysShowAmazonDisclosure
            />
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ m: 2 }}
            >
              {`©${CURRENT_YEAR} BTTB.io`}
            </Typography>
          </FooterMenu>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Footer;
