import {
  ExpandLessRounded as IconCollapse,
  ExpandMoreRounded as IconExpand,
} from '@mui/icons-material';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { isBrowser } from '../helpers/utils';
import Link from './Link';

const DESKTOP_MENU_BREAKPOINT = 'md';

const DesktopMenuItem = ({ text, href, icon, submenu, title }) => {
  const hasSubmenu = submenu.length > 0;
  const buttonIdentifier = `primary-menu-${kebabCase(title)}`;
  const submenuIdentifier = `${buttonIdentifier}-menu`;

  const [anchorEl, setAnchorEl] = useState(null);
  const isSubmenuOpen = Boolean(anchorEl);
  const closeSubmenu = useCallback(() => setAnchorEl(null), []);
  const openSubmenu = useCallback(
    ({ currentTarget }) => setAnchorEl(currentTarget),
    [],
  );

  return (
    <>
      <Button
        aria-controls={isSubmenuOpen ? submenuIdentifier : undefined}
        aria-haspopup={hasSubmenu ? 'true' : undefined}
        aria-expanded={isSubmenuOpen ? 'true' : undefined}
        color="inherit"
        component={!hasSubmenu ? Link : 'div'}
        endIcon={
          hasSubmenu && (isSubmenuOpen ? <IconCollapse /> : <IconExpand />)
        }
        href={!hasSubmenu ? href : undefined}
        id={buttonIdentifier}
        onClick={hasSubmenu ? openSubmenu : undefined}
        size="small"
        startIcon={icon}
        sx={{
          whiteSpace: 'nowrap',
          '&.current-page': {
            bgcolor: 'action.hover',
            borderBottom: ({ palette }) =>
              `0.125em solid ${palette.primary.dark}`,
          },
        }}
        title={title}
      >
        {text}
      </Button>
      {hasSubmenu && (
        <Menu
          id={submenuIdentifier}
          anchorEl={anchorEl}
          open={isSubmenuOpen}
          onClose={closeSubmenu}
          MenuListProps={{
            'aria-labelledby': buttonIdentifier,
          }}
        >
          {submenu.map((sm) => {
            return (
              <MenuItem
                key={sm.text}
                component={Link}
                href={sm.href}
                onClick={closeSubmenu}
                selected={isBrowser && window.location.pathname === sm.href}
                title={sm.title}
              >
                {sm.icon && <ListItemIcon>{sm.icon}</ListItemIcon>}
                <ListItemText>{sm.text}</ListItemText>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

DesktopMenuItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  submenu: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      title: PropTypes.string,
    }).isRequired,
  ),
};

DesktopMenuItem.defaultProps = {
  icon: undefined,
  title: undefined,
  submenu: [],
};

const DesktopMenu = ({ menus }) => {
  // the menu contents
  const menuContent = useMemo(() => {
    return menus.map(({ text, href, divided, icon, submenu, title }) => {
      return (
        <DesktopMenuItem
          key={text}
          divided={divided}
          href={href}
          icon={icon}
          submenu={submenu}
          text={text}
          title={title}
        />
      );
    });
  }, [menus]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={{
        xs: 1,
        lg: 3,
      }}
      sx={{
        flexGrow: 1,
        ml: {
          xs: 2,
          lg: 3,
        },
        mr: 2,
        display: {
          xs: 'none',
          [DESKTOP_MENU_BREAKPOINT]: 'flex',
        },
      }}
    >
      {menuContent}
    </Stack>
  );
};

DesktopMenu.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      icon: PropTypes.node,
      text: PropTypes.string.isRequired,
      title: PropTypes.string,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
          title: PropTypes.string,
        }).isRequired,
      ),
    }).isRequired,
  ).isRequired,
};

export default DesktopMenu;

export { DESKTOP_MENU_BREAKPOINT };
