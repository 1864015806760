import React, { useCallback, useState } from 'react';
import { useExitIntent } from 'use-exit-intent';

import NewsletterSignupDialog from './NewsletterSignupDialog';

const NEWSLETTER_DIALOG_ID = 'newsletter-exit-intent';
const ExitIntentNewsletterSignupDialog = () => {
  const { registerHandler, unsubscribe } = useExitIntent({
    cookie: {
      key: 'newsletter-exit-intent',
      // one month + a few days prevents out subscribers from seeing this on every visit
      daysToExpire: 34,
    },
  });

  // modal handling
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => {
    // close the dialog
    setIsOpen(false);
    // unsubscribe the user from the exit intent
    unsubscribe();
  }, [unsubscribe]);

  registerHandler({
    id: NEWSLETTER_DIALOG_ID,
    handler: handleOpen,
  });

  return (
    <NewsletterSignupDialog
      id={NEWSLETTER_DIALOG_ID}
      onClose={handleClose}
      onOpen={handleOpen}
      open={isOpen}
    />
  );
};

export default ExitIntentNewsletterSignupDialog;
