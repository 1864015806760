import { MoneyOffRounded as IconNewsletterSignup } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Link as MuiLink,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { urls } from '../helpers/urls';
import { NEWSLETTER_SIGNUP_URL } from '../helpers/utils';

// content is stored as constants for importing into other components
const FORM_TITLE = 'Save Big Each Month!';
const FORM_DESCRIPTION =
  'Subscribe now for FREE and get a monthly list of the product categories that are most steeply discounted for the coming month.';
const NewsletterSignupFormFooter = () => (
  <>
    {`You'll receive one email on the 1st of each month. `}
    <MuiLink
      color="inherit"
      href={urls.privacy}
      target="_blank"
      underline="always"
    >
      We respect your privacy
    </MuiLink>
    {` and won't share your information.`}
  </>
);

// @todo link to sample issue: https://us13.campaign-archive.com/?u=a5df4185b71d91920f3d7e073&id=3f86e90db6
const NewsletterSignupForm = ({ compact, id, orientation, sx, variant }) => {
  const [hpInput, setHPInput] = useState(''); // honeypot input

  const onHPInputChange = useCallback(({ target }) => {
    setHPInput(target.value);
  }, []);

  return (
    <Paper
      id={id}
      sx={{
        p: compact ? 1.5 : 2,
        pt: 0,
        borderStyle: 'solid',
        borderWidth: compact ? 0 : 3,
        borderColor: 'transparent',
        borderLeftColor: 'secondary.main', // by default, only left border is visible
        // make entire border visible when element is focused by fragment identifier
        '&:target': {
          borderColor: 'secondary.main',
        },
        ...sx,
      }}
      variant={variant}
    >
      <Avatar
        sx={{
          bgcolor: 'secondary.main',
          width: 36,
          height: 36,
          transform: 'translateY(-25%)',
          ml: 0,
        }}
      >
        <IconNewsletterSignup />
      </Avatar>
      <Typography
        component="div"
        variant="button"
      >
        {FORM_TITLE}
      </Typography>
      <Typography
        color="textSecondary"
        variant="body2"
      >
        {FORM_DESCRIPTION}
      </Typography>
      <Box
        action={NEWSLETTER_SIGNUP_URL}
        component="form"
        method="POST"
      >
        <Stack
          alignItems={{
            xs: 'stretch',
            sm: orientation === 'horizontal' ? 'flex-end' : 'stretch',
          }}
          direction={{
            xs: 'column',
            sm: orientation === 'horizontal' ? 'row' : 'column',
          }}
          spacing={1.5}
        >
          <TextField
            autoComplete="email"
            id={`${id}-email`}
            label="Email"
            name="EMAIL"
            placeholder="my@email.com"
            size={compact ? 'small' : 'medium'}
            sx={{ minWidth: 240 }}
            type="email"
            variant="standard"
            required
          />
          <TextField
            autoComplete="given-name"
            id={`${id}-first-name`}
            label="First name"
            name="FNAME"
            size={compact ? 'small' : 'medium'}
            sx={{ minWidth: 120 }}
            type="text"
            variant="standard"
            required
          />
        </Stack>
        <Button
          size={compact ? 'small' : 'medium'}
          sx={{ mt: 2 }}
          type="submit"
          variant="outlined"
        >
          Start saving now
        </Button>
        <input
          name="MMERGE6"
          type="hidden"
          value={id}
        />
        <Box
          aria-label="Email address"
          component="input"
          name="b_a5df4185b71d91920f3d7e073_a2721b7794"
          onChange={onHPInputChange}
          sx={{
            ml: '-15000px !important',
            position: 'absolute',
            width: 150,
          }}
          tabIndex={-1}
          type="text"
          value={hpInput}
        />
        <Typography
          sx={{ color: 'text.secondary', mt: 2 }}
          variant="body2"
        >
          <NewsletterSignupFormFooter />
        </Typography>
      </Box>
    </Paper>
  );
};

NewsletterSignupForm.propTypes = {
  compact: PropTypes.bool,
  id: PropTypes.string.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  sx: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  variant: PropTypes.oneOf(['outlined', 'elevation']),
};

NewsletterSignupForm.defaultProps = {
  compact: false,
  orientation: 'horizontal',
  sx: {},
  variant: 'outlined',
};

export default NewsletterSignupForm;

export { FORM_TITLE, FORM_DESCRIPTION, NewsletterSignupFormFooter };
