import {
  MapRounded as IconExplore,
  BugReportRounded as IconFeedback,
  MenuRounded as IconMenu,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { sortBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import {
  IconHolidays,
  IconMonthly,
  IconToday,
  getProductIcon,
  iconPopular,
} from '../helpers/icons';
import { urls } from '../helpers/urls';
import Logo from '../images/logos/logo-color.svg';
import AlgoliaSearchButton from './AlgoliaSearchButton';
import DesktopMenu, { DESKTOP_MENU_BREAKPOINT } from './DesktopMenu';
import FAIcon from './FAIcon';
import HideOnScroll from './HideOnScroll';
import Link from './Link';
import MenuDrawer from './MenuDrawer';

const MenuBar = React.memo(() => {
  const { mixins } = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = useCallback(() => setMenuOpen(false), []);
  const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);
  const data = useStaticQuery(graphql`
    query MenuDrawerQuery {
      popularProducts: allSitePage(
        filter: {
          component: {
            regex: "/^.*/templates/product.jsx.*$/" # only include products using the product template
          }
          context: { popular: { eq: true } }
        }
      ) {
        edges {
          node {
            path
            context {
              articleTitle
              identifier
              name
            }
          }
        }
      }
      months: allSitePage(
        filter: {
          component: {
            regex: "/^.*/templates/month.jsx$/" # only include months using the month template
          }
        }
      ) {
        edges {
          node {
            path
            context {
              name
              number
            }
          }
        }
      }
    }
  `);
  const popularProducts = useMemo(
    () =>
      sortBy(
        data.popularProducts.edges.map(({ node }) => ({
          text: node.context.name,
          href: node.path,
          icon: getProductIcon(node.context.identifier),
          title: node.context.articleTitle,
        })),
        ['text'],
      ),
    [data.popularProducts.edges],
  );
  const months = useMemo(
    () =>
      sortBy(
        data.months.edges.map(({ node }) => ({
          text: node.context.name,
          href: node.path,
          title: `What to buy in ${node.context.name}`,
          number: node.context.number,
        })),
        ['number'],
      ),
    [data.months.edges],
  );
  const { mobileMenu, desktopMenu } = useMemo(() => {
    return {
      // mobile menu
      mobileMenu: [
        {
          text: 'Explore',
          href: urls.home,
          icon: <IconExplore />,
        },
        {
          text: 'Calendar',
          href: urls.monthly,
          icon: <IconMonthly />,
          submenu: [
            {
              text: 'Explore All Months',
              href: urls.monthly,
            },
            ...months,
          ],
        },
        {
          text: 'Popular',
          href: '/#footer-popular',
          icon: <FAIcon icon={iconPopular} />,
          submenu: popularProducts,
        },
        {
          text: 'What to Buy Today',
          href: urls.today,
          icon: <IconToday />,
        },
        {
          text: 'What to Buy Each Holiday',
          href: urls.holidays,
          icon: <IconHolidays />,
        },
        {
          text: 'Feedback',
          href: urls.contact,
          icon: <IconFeedback />,
        },
      ],
      // desktop menu
      desktopMenu: [
        {
          text: 'Explore',
          href: urls.home,
          icon: <IconExplore />,
        },
        {
          text: 'Calendar',
          href: urls.monthly,
          icon: <IconMonthly />,
          submenu: [
            {
              text: 'Explore All Months',
              href: urls.monthly,
              title: 'What to Buy Each Month',
            },
            ...months,
          ],
        },
        {
          text: 'Popular',
          href: '/#footer-popular',
          icon: <FAIcon icon={iconPopular} />,
          submenu: popularProducts,
        },
        {
          text: 'Today',
          title: 'What to Buy Today',
          href: urls.today,
          icon: <IconToday />,
        },
        {
          text: 'Holidays',
          title: 'What to Buy Each Holiday',
          href: urls.holidays,
          icon: <IconHolidays />,
        },
        /*
        {
          text: 'More',
          submenu: [
            {
              text: 'Feedback',
              href: urls.contact,
              icon: <IconFeedback />,
            },
          ],
        },
        */
      ],
    };
  }, [popularProducts, months]);

  return (
    <>
      <Box
        id="top"
        sx={{
          ...mixins.toolbar,
          mb: {
            xs: 3,
            [DESKTOP_MENU_BREAKPOINT]: 4,
          },
        }}
      />
      <HideOnScroll>
        <AppBar elevation={2}>
          <Toolbar
            sx={{
              width: '100%',
              maxWidth: 'xl',
              mx: 'auto',
            }}
          >
            <IconButton
              aria-label="Toggle menu"
              edge="start"
              onClick={toggleMenu}
              size="large"
              sx={{
                display: {
                  [DESKTOP_MENU_BREAKPOINT]: 'none',
                },
              }}
            >
              <IconMenu />
            </IconButton>
            <Box
              sx={{
                textAlign: 'center',
                flexGrow: {
                  xs: 1,
                  [DESKTOP_MENU_BREAKPOINT]: 0,
                },
              }}
            >
              <Button
                component={Link}
                sx={{
                  display: 'inline-block',
                  fontSize: 0,
                }}
                href={urls.home}
                title="Best Time to Buy"
                underline="none"
              >
                <Logo height={36} />
                <Typography
                  sx={{
                    color: 'primary.main',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    fontSize: '11px',
                    lineHeight: 1,
                    mt: 0.5,
                    display: 'block',
                    textAlign: 'center',
                  }}
                  variant="overline"
                >
                  Best Time to Buy
                  <Box
                    component="span"
                    sx={{ fontSize: '0.75em', verticalAlign: 'top' }}
                  >
                    &reg;
                  </Box>
                </Typography>
              </Button>
            </Box>
            <DesktopMenu menus={desktopMenu} />
            <AlgoliaSearchButton />
          </Toolbar>
          <MenuDrawer
            menus={mobileMenu}
            onClose={closeMenu}
            open={menuOpen}
          />
        </AppBar>
      </HideOnScroll>
    </>
  );
});

export default MenuBar;
