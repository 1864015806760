import { FavoriteBorderRounded as IconHeart } from '@mui/icons-material';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const AffiliateDisclosure = ({ alwaysShowAmazonDisclosure, offers, sx }) => {
  // only show the disclosure if there's an Amazon offer
  const showAmazonDisclosure =
    alwaysShowAmazonDisclosure ||
    offers?.some(({ source }) => source?.toLowerCase().includes('amazon'));

  if (!showAmazonDisclosure) return null;

  return (
    <Typography
      variant="body2"
      color="textSecondary"
      fontStyle="italic"
      sx={sx}
    >
      <IconHeart
        sx={{
          fontSize: '1em',
          mb: -0.25,
          mr: 0.5,
        }}
      />
      BTTB is reader-supported. As an Amazon Associate, we may earn a small
      commission at no cost to you when you buy through our links.
    </Typography>
  );
};

AffiliateDisclosure.propTypes = {
  alwaysShowAmazonDisclosure: PropTypes.bool,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      href: PropTypes.string,
    }),
  ),
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

AffiliateDisclosure.defaultProps = {
  alwaysShowAmazonDisclosure: false,
  offers: [],
  sx: {},
};

export default AffiliateDisclosure;
