import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Script } from 'gatsby';
import React from 'react';

// Algolia styling can be found in theme config
// @todo use React instead: https://www.algolia.com/doc/api-reference/widgets/search-box/react/
const ALGOLIA_SEARCH_CSS_FILE_ID = 'algolia-search-css';
const AlgoliaSearchScript = () => {
  const { palette } = useTheme();
  return (
    <Script
      onError={(e) => {
        // eslint-disable-next-line no-console
        console.error('Failed to load Algolia search script', e);
      }}
      onLoad={() => {
        if (typeof window?.algoliasearchNetlify === 'function') {
          // if this onLoad function has already been executed, do nothing
          // we check this by checking for the existence of the Algolia CSS file
          if (document.getElementById(ALGOLIA_SEARCH_CSS_FILE_ID)) return;

          // load required CSS on frontend
          const head = document.getElementsByTagName('head')[0];
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href =
            'https://cdn.jsdelivr.net/npm/@algolia/algoliasearch-netlify-frontend@1/dist/algoliasearchNetlify.css';
          link.id = ALGOLIA_SEARCH_CSS_FILE_ID;
          head.appendChild(link);

          // execute the JS
          window.algoliasearchNetlify({
            apiKey: process.env.ALGOLIA_SEARCHONLY_API_KEY,
            appId: 'QYA3T103VD',
            branch: 'master',
            detached: true,
            placeholder: 'What do you want to buy?',
            hitsPerPage: 10,
            selector: '#search',
            debug: false,
            siteId: 'db9229de-0434-4cd5-b644-a14f2a805dc8',
            theme: {
              background: '#1e1e1e', // approximately equal to paper background color
              colorSourceIcon: palette.primary.main,
              mark: palette.text.secondary,
              selected: alpha(palette.primary.main, 0.1),
              text: palette.text.primary,
            },
          });
        } else {
          // eslint-disable-next-line no-console
          console.warn(
            'Failed to execute function window.algoliasearchNetlify',
          );
        }
      }}
      src="https://cdn.jsdelivr.net/npm/@algolia/algoliasearch-netlify-frontend@1/dist/algoliasearchNetlify.js"
      strategy="idle"
    />
  );
};
export default AlgoliaSearchScript;
