import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { NEWSLETTER_SIGNUP_URL } from '../helpers/utils';
import LogoMonogram from '../images/logos/logo-monogram-color.svg';
import {
  FORM_DESCRIPTION,
  FORM_TITLE,
  NewsletterSignupFormFooter,
} from './NewsletterSignupForm';
import TypographyList from './TypographyList';

const NEWSLETTER_BENEFITS = [
  'A manually-curated list of what you can save the most money on each month.',
  `Data-backed reasons why it's a great time to buy each one.`,
  'A sneak peek into the latest BTTB site news and features.',
];

const NewsletterSignupDialog = ({ children, id, onClose, onOpen, open }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hpInput, setHPInput] = useState(''); // honeypot input

  const handleOpen = useCallback(() => {
    // if open prop is passed to this component, manage state externally
    if (typeof open !== 'boolean') {
      setIsOpen(true);
    }
    onOpen(); // prop-based callback
  }, [onOpen, open]);
  const handleClose = useCallback(() => {
    // if open prop is passed to this component, manage state externally
    if (typeof open !== 'boolean') {
      setIsOpen(false);
    }
    onClose(); // prop-based callback
  }, [onClose, open]);

  const onHPInputChange = useCallback(
    ({ target }) => setHPInput(target.value),
    [],
  );

  return (
    <>
      {children && (
        <Box
          component="span"
          onClick={handleOpen}
        >
          {children}
        </Box>
      )}
      <Dialog
        action={NEWSLETTER_SIGNUP_URL}
        component="form"
        method="POST"
        onClose={handleClose}
        open={open || isOpen}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Stack
            alignItems="center"
            direction="row"
            spacing={1.5}
          >
            <SvgIcon>
              <LogoMonogram />
            </SvgIcon>
            <div>{FORM_TITLE}</div>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                light
              />
            }
            spacing={2}
          >
            <div>
              <DialogContentText sx={{ color: 'inherit' }}>
                {FORM_DESCRIPTION}
              </DialogContentText>
              <Stack
                direction="column"
                spacing={1.5}
              >
                <TextField
                  autoComplete="email"
                  id={`${id}-email`}
                  label="Email"
                  name="EMAIL"
                  placeholder="my@email.com"
                  sx={{ minWidth: 240 }}
                  type="email"
                  variant="standard"
                  required
                />
                <TextField
                  autoComplete="given-name"
                  id={`${id}-first-name`}
                  label="First name"
                  name="FNAME"
                  sx={{ minWidth: 120 }}
                  type="text"
                  variant="standard"
                  required
                />
              </Stack>
              <input
                name="MMERGE6"
                type="hidden"
                value={id}
              />
              <Box
                aria-label="Email address"
                component="input"
                name="b_a5df4185b71d91920f3d7e073_a2721b7794"
                onChange={onHPInputChange}
                sx={{
                  ml: '-15000px !important',
                  position: 'absolute',
                  width: 150,
                }}
                tabIndex={-1}
                type="text"
                value={hpInput}
              />
            </div>
            <div>
              <Typography
                color="text.secondary"
                variant="subtitle2"
                gutterBottom
              >
                What's in it?
              </Typography>
              <TypographyList
                color="text.secondary"
                items={NEWSLETTER_BENEFITS}
                sx={{ m: 0 }}
                variant="body2"
              />
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ color: 'text.secondary' }}
            tabIndex={-1}
          >
            Close
          </Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
        <Divider
          variant="middle"
          light
        />
        <DialogContent sx={{ py: 2 }}>
          <DialogContentText variant="body2">
            <NewsletterSignupFormFooter />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

NewsletterSignupDialog.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
};

NewsletterSignupDialog.defaultProps = {
  children: undefined,
  onClose: noop,
  onOpen: noop,
  open: undefined,
};

export default NewsletterSignupDialog;
