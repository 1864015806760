const socialURLs = {
  facebook: 'https://www.facebook.com/bttb.io',
  instagram: 'https://www.instagram.com/bttb.io/',
};

const urls = {
  home: '/',
  contact: '/contact',
  holidays: '/holidays',
  monthly: '/what-to-buy-every-month',
  myDataRights: '/my-data-rights',
  privacy: '/privacy-policy',
  today: '/today',
};

// return an author page URL
const getAuthorURL = (identifier) => `/team/${identifier}`;

// return a holiday page URL
const getHolidayURL = (identifier) => `/holidays/${identifier}`;

export { getAuthorURL, getHolidayURL, socialURLs, urls };
