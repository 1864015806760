exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-holidays-jsx": () => import("./../../../src/pages/holidays.jsx" /* webpackChunkName: "component---src-pages-holidays-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-data-rights-jsx": () => import("./../../../src/pages/my-data-rights.jsx" /* webpackChunkName: "component---src-pages-my-data-rights-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-today-jsx": () => import("./../../../src/pages/today.jsx" /* webpackChunkName: "component---src-pages-today-jsx" */),
  "component---src-pages-what-to-buy-every-month-jsx": () => import("./../../../src/pages/what-to-buy-every-month.jsx" /* webpackChunkName: "component---src-pages-what-to-buy-every-month-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-holiday-jsx": () => import("./../../../src/templates/holiday.jsx" /* webpackChunkName: "component---src-templates-holiday-jsx" */),
  "component---src-templates-month-jsx": () => import("./../../../src/templates/month.jsx" /* webpackChunkName: "component---src-templates-month-jsx" */),
  "component---src-templates-product-jsx-content-file-path-content-products-air-fryers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/air-fryers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-air-fryers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-air-purifiers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/air-purifiers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-air-purifiers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-apple-watches-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/apple-watches.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-apple-watches-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-appliances-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/appliances.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-appliances-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-area-rugs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/area-rugs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-area-rugs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-artificial-christmas-trees-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/artificial-christmas-trees.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-artificial-christmas-trees-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-baby-clothes-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/baby-clothes.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-baby-clothes-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-baby-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/baby-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-baby-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-backpacks-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/backpacks.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-backpacks-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-bath-towels-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/bath-towels.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-bath-towels-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-bed-sheets-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/bed-sheets.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-bed-sheets-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-bedding-and-linens-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/bedding-and-linens.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-bedding-and-linens-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-bedroom-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/bedroom-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-bedroom-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-bicycles-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/bicycles.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-bicycles-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-blenders-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/blenders.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-blenders-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-board-games-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/board-games.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-board-games-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-books-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/books.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-books-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-camping-gear-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/camping-gear.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-camping-gear-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-candles-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/candles.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-candles-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-carpet-cleaners-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/carpet-cleaners.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-carpet-cleaners-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-cars-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/cars.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-cars-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-chest-freezers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/chest-freezers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-chest-freezers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-clothing-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/clothing.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-clothing-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-coffee-makers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/coffee-makers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-coffee-makers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-coffee-tables-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/coffee-tables.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-coffee-tables-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-cologne-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/cologne.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-cologne-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-computer-hardware-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/computer-hardware.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-computer-hardware-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-computer-keyboards-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/computer-keyboards.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-computer-keyboards-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-computer-mice-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/computer-mice.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-computer-mice-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-computer-monitors-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/computer-monitors.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-computer-monitors-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-computers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/computers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-computers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-couches-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/couches.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-couches-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-cruises-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/cruises.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-cruises-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-curtains-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/curtains.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-curtains-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-dehumidifiers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/dehumidifiers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-dehumidifiers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-desktop-computers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/desktop-computers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-desktop-computers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-digital-cameras-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/digital-cameras.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-digital-cameras-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-dining-room-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/dining-room-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-dining-room-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-dishwashers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/dishwashers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-dishwashers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-dna-testing-kits-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/dna-testing-kits.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-dna-testing-kits-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-down-comforters-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/down-comforters.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-down-comforters-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-drums-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/drums.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-drums-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-e-readers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/e-readers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-e-readers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-earbuds-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/earbuds.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-earbuds-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-electric-scooters-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/electric-scooters.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-electric-scooters-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-electric-tea-kettles-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/electric-tea-kettles.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-electric-tea-kettles-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-electronics-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/electronics.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-electronics-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-engagement-rings-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/engagement-rings.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-engagement-rings-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-eyeglasses-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/eyeglasses.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-eyeglasses-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-fitness-equipment-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/fitness-equipment.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-fitness-equipment-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-fitness-trackers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/fitness-trackers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-fitness-trackers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-floor-lamps-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/floor-lamps.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-floor-lamps-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-food-processors-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/food-processors.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-food-processors-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-freezers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/freezers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-freezers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-gaming-keyboards-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/gaming-keyboards.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-gaming-keyboards-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-gaming-pcs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/gaming-pcs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-gaming-pcs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-grills-and-bbqs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/grills-and-bbqs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-grills-and-bbqs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-guitars-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/guitars.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-guitars-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-hair-dryers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/hair-dryers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-hair-dryers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-hair-tools-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/hair-tools.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-hair-tools-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-haircare-products-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/haircare-products.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-haircare-products-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-hammocks-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/hammocks.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-hammocks-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-headphones-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/headphones.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-headphones-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-hiking-boots-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/hiking-boots.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-hiking-boots-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-hiking-gear-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/hiking-gear.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-hiking-gear-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-home-decor-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/home-decor.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-home-decor-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-hot-tubs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/hot-tubs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-hot-tubs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-ipads-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/ipads.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-ipads-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-iphones-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/iphones.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-iphones-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-jewelry-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/jewelry.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-jewelry-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-juicers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/juicers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-juicers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-kids-bedroom-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/kids-bedroom-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-kids-bedroom-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-kitchen-appliances-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/kitchen-appliances.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-kitchen-appliances-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-lamps-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/lamps.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-lamps-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-laptops-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/laptops.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-laptops-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-lawn-mowers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/lawn-mowers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-lawn-mowers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-living-room-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/living-room-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-living-room-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-luggage-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/luggage.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-luggage-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-macbooks-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/macbooks.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-macbooks-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-makeup-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/makeup.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-makeup-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-mattresses-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/mattresses.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-mattresses-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-microsoft-xbox-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/microsoft-xbox.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-microsoft-xbox-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-microwaves-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/microwaves.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-microwaves-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-musical-instruments-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/musical-instruments.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-musical-instruments-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-nintendo-switch-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/nintendo-switch.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-nintendo-switch-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-office-chairs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/office-chairs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-office-chairs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-office-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/office-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-office-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-outdoor-gear-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/outdoor-gear.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-outdoor-gear-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-patio-furniture-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/patio-furniture.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-patio-furniture-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-perfumes-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/perfumes.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-perfumes-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-pianos-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/pianos.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-pianos-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-pillowcases-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/pillowcases.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-pillowcases-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-plants-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/plants.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-plants-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-power-tools-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/power-tools.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-power-tools-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-pressure-washers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/pressure-washers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-pressure-washers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-printers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/printers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-printers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-purses-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/purses.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-purses-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-ranges-stoves-or-ovens-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/ranges-stoves-or-ovens.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-ranges-stoves-or-ovens-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-recliners-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/recliners.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-recliners-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-refrigerators-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/refrigerators.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-refrigerators-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-rice-cookers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/rice-cookers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-rice-cookers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-robot-vacuums-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/robot-vacuums.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-robot-vacuums-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-rugs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/rugs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-rugs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-shoes-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/shoes.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-shoes-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-shop-vacs-or-wet-dry-vacuums-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/shop-vacs-or-wet-dry-vacuums.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-shop-vacs-or-wet-dry-vacuums-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-side-or-end-tables-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/side-or-end-tables.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-side-or-end-tables-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-ski-boots-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/ski-boots.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-ski-boots-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-ski-gear-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/ski-gear.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-ski-gear-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-ski-jackets-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/ski-jackets.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-ski-jackets-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-skincare-products-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/skincare-products.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-skincare-products-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-skis-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/skis.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-skis-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-slow-cookers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/slow-cookers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-slow-cookers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-smartphones-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/smartphones.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-smartphones-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-snow-blowers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/snow-blowers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-snow-blowers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-sony-playstation-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/sony-playstation.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-sony-playstation-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-suitcases-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/suitcases.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-suitcases-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-summer-clothes-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/summer-clothes.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-summer-clothes-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-sunglasses-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/sunglasses.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-sunglasses-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-tablets-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/tablets.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-tablets-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-tents-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/tents.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-tents-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-tires-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/tires.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-tires-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-toasters-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/toasters.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-toasters-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-tool-chests-or-cabinets-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/tool-chests-or-cabinets.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-tool-chests-or-cabinets-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-tools-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/tools.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-tools-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-travel-gear-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/travel-gear.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-travel-gear-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-treadmills-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/treadmills.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-treadmills-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-trucks-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/trucks.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-trucks-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-tvs-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/tvs.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-tvs-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-upright-freezers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/upright-freezers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-upright-freezers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-vacuum-cleaners-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/vacuum-cleaners.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-vacuum-cleaners-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-video-doorbells-or-doorbell-cameras-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/video-doorbells-or-doorbell-cameras.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-video-doorbells-or-doorbell-cameras-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-video-game-consoles-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/video-game-consoles.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-video-game-consoles-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-video-games-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/video-games.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-video-games-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-washers-and-dryers-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/washers-and-dryers.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-washers-and-dryers-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-wedding-dresses-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/wedding-dresses.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-wedding-dresses-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-weed-whackers-or-weed-eaters-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/weed-whackers-or-weed-eaters.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-weed-whackers-or-weed-eaters-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-winter-clothes-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/winter-clothes.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-winter-clothes-md" */),
  "component---src-templates-product-jsx-content-file-path-content-products-winter-jackets-md": () => import("./../../../src/templates/product.jsx?__contentFilePath=/opt/build/repo/content/products/winter-jackets.md" /* webpackChunkName: "component---src-templates-product-jsx-content-file-path-content-products-winter-jackets-md" */)
}

