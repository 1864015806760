import { Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import React from 'react';

const TypographyList = ({ color, items, component, sx, variant }) => (
  <Typography
    color={color}
    component={component}
    sx={{
      marginBlockStart: 0,
      marginBlockEnd: 0,
      my: 2,
      ml: 2,
      pl: 0,
      listStylePosition: component === 'ol' ? 'inside' : 'outside',
      ...sx,
    }}
    variant={variant}
  >
    {items.map((item, i) => (
      <Typography
        key={typeof item === 'string' ? item : nanoid()}
        component="li"
        gutterBottom={i + 1 < items.length}
        sx={{
          listStylePosition: 'outside',
          ml: 2,
        }}
        variant={variant}
      >
        {item}
      </Typography>
    ))}
  </Typography>
);

TypographyList.propTypes = {
  color: PropTypes.oneOf(['text.primary', 'text.secondary']),
  component: PropTypes.oneOf(['ul', 'ol']),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['body1', 'body2']),
};

TypographyList.defaultProps = {
  color: 'text.primary',
  component: 'ul',
  sx: {},
  variant: 'body1',
};

export default TypographyList;
