import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { log } from '../helpers/utils';
import { theme } from '../theme';
import CMPScript from './CMPScript';
import PageLayout from './PageLayout';

const activeTheme = createTheme(theme);

const ContextProviderLayout = ({ children }) => {
  log('theme:');
  log(activeTheme);

  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline id="mui-css-baseline" />
      <PageLayout>{children}</PageLayout>
      <CMPScript />
    </ThemeProvider>
  );
};

ContextProviderLayout.propTypes = {
  children: PropTypes.node,
};

ContextProviderLayout.defaultProps = {
  children: null,
};

export default ContextProviderLayout;
