import { Box } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { isTouch } from '../helpers/utils';
import { DESKTOP_MENU_BREAKPOINT } from './DesktopMenu';

const useCommandK = (ref) => {
  const onHotkeyPress = useCallback(
    (e) => {
      if (e.keyCode === 75 && e.metaKey) {
        e.preventDefault();
        try {
          ref.current
            .getElementsByClassName('aa-DetachedSearchButton')[0]
            .click();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error focusing search input', error);
        }
      }
    },
    [ref],
  );

  useEffect(() => {
    document.addEventListener('keydown', onHotkeyPress);
    return () => document.removeEventListener('keydown', onHotkeyPress);
  }, [onHotkeyPress]);
};

const AlgoliaSearchButton = React.memo(() => {
  const [showCommandK, setShowCommandK] = useState(false);
  const commandKRef = useRef();
  useCommandK(commandKRef);

  // to prevent SSR issues, this check must be run in useEffect
  useEffect(() => {
    setShowCommandK(!isTouch);
  }, []);

  return (
    <Box
      ref={commandKRef}
      id="search"
      sx={{
        mr: -1.5, // mirror edge="end" MuiButton behavior
        // prevents CLS
        minWidth: {
          xs: 36,
          [DESKTOP_MENU_BREAKPOINT]: 82, // larger min-width to accommodate ⌘-K
        },
        '--aa-search-input-height': '40px',
        '--aa-primary-color-rgb': '255, 87, 34',
        // wrapper
        // '& .aa-Autocomplete': {},
        // button itself
        '& .aa-DetachedSearchButton': {
          borderRadius: 1,
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          px: 0,
          '& .aa-DetachedSearchButtonIcon': {
            color: ({ palette }) => palette.text.primary,
            cursor: 'pointer',
          },
          '& .aa-DetachedSearchButtonPlaceholder': {
            display: 'none', // hide the placeholder for this placement
          },
          '&:hover': {
            bgcolor: 'action.hover',
          },
          '&::after': {
            backgroundColor: 'action.hover',
            borderRadius: 0.75,
            color: ({ palette }) => palette.text.secondary,
            content: '"⌘-K"',
            whiteSpace: 'nowrap',
            display: {
              xs: 'none',
              [DESKTOP_MENU_BREAKPOINT]: showCommandK ? 'block' : 'none',
            },
            fontSize: '0.75em',
            mr: 1,
            px: 0.75,
            py: 0.5,
          },
        },
      }}
    />
  );
});

export default AlgoliaSearchButton;
