import {
  CloseRounded as IconClose,
  ExpandLessRounded as IconCollapse,
  ExpandMoreRounded as IconExpand,
} from '@mui/icons-material';
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { without } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { urls } from '../helpers/urls';
import { isBrowser } from '../helpers/utils';
import Logo from '../images/logos/logo.svg';
import Link from './Link';

const MenuDrawer = ({ menus, open, onClose }) => {
  const { transitions } = useTheme();
  const [visibleSubmenus, setVisibleSubmenus] = useState([]);
  const currentPathname = isBrowser && window?.location?.pathname;

  // expand or contract multiple menu items
  const handleSubmenuToggle = useCallback(
    (href) => {
      if (visibleSubmenus.includes(href)) {
        setVisibleSubmenus(without(visibleSubmenus, href));
      } else {
        setVisibleSubmenus([...visibleSubmenus, href]);
      }
    },
    [visibleSubmenus],
  );

  // the menu contents
  const menuContent = useMemo(() => {
    return (
      <List
        component="nav"
        sx={{ mt: '0 !important' }}
        disablePadding
      >
        {menus.map(({ text, href, divided, icon, submenu, title }) => {
          const hasSubmenu = submenu?.length > 0;
          const submenuIsOpen = hasSubmenu && visibleSubmenus.includes(href);
          const isCurrentPage = currentPathname === href;

          return (
            <React.Fragment key={text}>
              <ListItemButton
                component={!hasSubmenu ? Link : 'div'}
                divider={divided}
                href={!hasSubmenu ? href : undefined}
                onClick={() => {
                  if (hasSubmenu) {
                    handleSubmenuToggle(href);
                  } else {
                    onClose();
                  }
                }}
                selected={isCurrentPage && !hasSubmenu}
                title={title}
              >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={text} />
                {submenuIsOpen && <IconCollapse />}
                {hasSubmenu && !submenuIsOpen && <IconExpand />}
              </ListItemButton>
              {hasSubmenu && (
                <Collapse
                  in={submenuIsOpen}
                  sx={{ bgcolor: 'background.default' }}
                  timeout={{
                    appear: transitions.duration.enteringScreen,
                    enter: transitions.duration.enteringScreen,
                    exit: transitions.duration.leavingScreen,
                  }}
                  unmountOnExit
                >
                  <List
                    component="nav"
                    dense
                  >
                    {submenu.map((sm) => {
                      const isCurrentSubmenuPage =
                        isBrowser && window.location.pathname === sm.href;
                      return (
                        <ListItemButton
                          key={sm.text}
                          component={Link}
                          href={sm.href}
                          onClick={onClose}
                          selected={isCurrentSubmenuPage}
                          title={sm.title}
                        >
                          {sm.icon && <ListItemIcon>{sm.icon}</ListItemIcon>}
                          <ListItemText
                            primary={sm.text}
                            inset={!sm.icon}
                          />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    );
  }, [
    currentPathname,
    handleSubmenuToggle,
    menus,
    onClose,
    transitions.duration.enteringScreen,
    transitions.duration.leavingScreen,
    visibleSubmenus,
  ]);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: 280,
        },
      }}
    >
      <Stack spacing={2}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{
            fontSize: 0, // fixes a padding issue
            padding: 2,
            pr: 1,
          }}
        >
          <Link
            onClick={onClose}
            sx={{
              color: 'text.secondary',
              '& svg': {
                fontSize: 16,
                maxWidth: '5em',
              },
            }}
            title="Best Time to Buy"
            href={urls.home}
            underline="none"
          >
            <Logo width="100%" />
            <Typography
              sx={{
                fontSize: '0.625rem',
                lineHeight: 1,
                ml: 0.5,
              }}
              variant="overline"
            >
              Beta
            </Typography>
          </Link>
          <IconButton
            aria-label="Toggle menu"
            onClick={onClose}
            sx={{ color: 'text.secondary' }}
          >
            <IconClose />
          </IconButton>
        </Stack>
        {menuContent}
      </Stack>
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      submenu: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          href: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }).isRequired,
  ).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MenuDrawer;
